/* Navbar.css */
.navbar {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  color: #000;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar .logo {
  height: 55px;
  width: 57px;
}

.nav-links {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 30px;
}
.buttonnv{
  background-image: url('../assets/ambg.webp');
  /* You can also specify other background properties like size and position if needed */
  background-size: cover; /* This will make sure the background image covers the button */
  background-position: center; /* This will position the background image at the center */
  /* You can add more styles like padding, color, etc. as per your design requirements */
  color: #fff;
  padding: 11px;
  width: 145px;
  
  border-radius: 8px;
  font-weight: bold;
  font-size: 20px;
  border: none;
  cursor: pointer;
}


.nav-links li a {
  text-decoration: none;
  color: #215ea3;
  font-size: 16px;
  font-weight: bold;
  /* margin-left: 22px; */
}

.enquiry-button {
  background-color: #51bfcd;
  color: #000;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 10px;
  font-weight: bold;
}

/* Navbar.css */
/* Add your existing styles here */

/* Mobile Menu Styles */
.navbar.open {
height: auto;
}

.mobile-menu-icon {
display: none;
flex-direction: column;
cursor: pointer;
z-index: 2; 
}

.mobile-menu-icon.open {
display: flex;
}

.bar {
width: 25px;
height: 3px;
background-color: #000;
margin: 4px 0;
}

@media (max-width: 768px) {
.mobile-menu-icon {
  display: flex;
}

.nav-links {
  flex-direction: column;
  position: absolute;
  top: 60px;
  left: -100%;
  width: 100%;
  background-color: #fff;
  transition: left 0.3s ease;
   gap: 12px;
   z-index: 1;
}
.nav-links li a {
 
  margin-left: 22px;
}


.nav-links.open {
  left: 0;
}
.desktop {
  display: none; /* Hide desktop button on mobile */
}
.enquiry-button {
 margin-left: 12px;
  z-index: 2;
}
.buttonnv{
  background-image: url('../assets/ambg.webp');
  /* You can also specify other background properties like size and position if needed */
  background-size: cover; /* This will make sure the background image covers the button */
  background-position: center; /* This will position the background image at the center */
  /* You can add more styles like padding, color, etc. as per your design requirements */
  color: #fff;
  padding: 11px;
  width: 131px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 15px;
  border: none;
  cursor: pointer;
  margin-left: 9px;
  margin-bottom: 4px;
}

}

@media (min-width: 769px) {
.enquiry-button-mobile {
  display: none; /* Hide mobile button on desktop */
}


}
