.floorplan-cont{
   
    padding: 20px;
    min-height: 400px;
}
.floorplanimg-cont{
    display: flex;
    justify-content: center;
    column-gap: 10px;
    padding: 10px;
    flex-wrap: wrap;
}
.floorplan-header{
    text-align: center;
    padding: 20px;
}
.floorplan-cont span{
    color:#937445;
}
.floorplan-img-btn-cont{
    width: 338px;
    position: relative;
}
.buttonf{
    background-image: url('../assets/ambg.webp');
    /* You can also specify other background properties like size and position if needed */
    background-size: cover; /* This will make sure the background image covers the button */
    background-position: center; /* This will position the background image at the center */
    /* You can add more styles like padding, color, etc. as per your design requirements */
    color: #fbf0b8;
    padding: 11px;
    width: 145px;
    
    border-radius: 8px;
    font-weight: bold;
    font-size: 20px;
    border: none;
    cursor: pointer;
  }

.floorplanimg-cont{
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 20px 0;
    flex-wrap: wrap;
}
.floorplanimg-cont img{
    width: 93%;
    height: 232px;
    display: block;
    filter: blur(2px);
    border: 2px solid #165291;
}
.floorplan-img-btn-cont button{
    position: absolute;
    width: 125px;
    padding: 9px;
    bottom: 23px;
    left: 0px;
    right: 30px;
    margin: auto;
    background-image: url('../assets/ambg.webp');
    color: white;
    border: none;
    border-radius: 8px;
    font-weight: bold;
}
.floorplan-img-btn-cont button:hover{
    cursor: pointer;
}
/* .floorplanimg-cont img:hover{
    box-shadow: 3px 3px 6px 2px grey;
} */
.headi{
    text-align: center;
     margin-top: 30px;
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: bold;
   
   }

   .flow{
    margin-left:34%;
    color: #165291;
    font-size: 23px;
   }
   .flowcs{
    margin-left:26%;
    color: #165291;
    font-size: 23px;
   }

   
   @media (max-width: 768px) {
    .floorplan-img-btn-cont{
        width: 371px;
        position: relative;
    }
    .headi{
        text-align: center;
         margin-top: 30px;
        margin-bottom: 30px;
        font-size: 24px;
        font-weight: bold;
       
       }
       

.floorplan-cont{
   
    padding: 20px;
    min-height: 356px;
}
}








   