.best-pricing-table-cont{
    margin: 29px;
    background-image: url("");
    background-repeat: no-repeat;
    background-size: cover;
}
.pricing-header-cont{
   justify-content: center;
}
.headside-border{
    border-right: 5px solid #B07936;
}
.table {
  border-collapse: collapse;
  width: 100%;
}
td{
    vertical-align: middle;
}
.button {
    background-image: url('../assets/ambg.webp');
    /* You can also specify other background properties like size and position if needed */
    background-size: cover; /* This will make sure the background image covers the button */
    background-position: center; /* This will position the background image at the center */
    /* You can add more styles like padding, color, etc. as per your design requirements */
    color: white;
    padding: 8px;
    width: 121px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 20px;
    border: none;
    cursor: pointer;
  }
tr{
    border-bottom: 1px solid rgba(8, 77, 108);
}
thead tr{
    border-bottom: none;
}
.table th,
.table td {
  border: 1px solid rgba(8, 77, 108);
  padding: 8px;
  text-align: center;
  text-align: -webkit-center;
  font-weight: bold;
}

.table p {
  width: 40%;
  margin: 0;
  padding: 8px;
 font-weight: bold;
 
}
thead{
    background: #eee595;
    color: #000;
}
tbody{
    background: #f7f5e5;
}
.price-btn{
    background: linear-gradient(to right, #165291, #165291);
    color: white;
    padding: 9px;
    border-radius: 7px;
    font-weight: bold;
}
.table-btn{
    vertical-align: middle;
}

.head{
    text-align: center;
     margin-top: 30px;
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: bold;
    /* color: rgb(179, 55, 104);
    text-decoration: underline; 
    text-decoration-color: rgba(8, 77, 108); */
   }
 
   
@media screen and (max-width:800px) {
    .best-pricing-table-cont{
        flex-direction: column;
        
    }
    .table th,
.table td {
  border: 1px solid rgba(8, 77, 108);
  padding: 11px;
  font-size: 12px;
  text-align: center;
  text-align: -webkit-center;
}
.button {
    background-image: url('../assets/ambg.webp');
    /* You can also specify other background properties like size and position if needed */
    background-size: cover; /* This will make sure the background image covers the button */
    background-position: center; /* This will position the background image at the center */
    /* You can add more styles like padding, color, etc. as per your design requirements */
    color: white;
    padding: 8px;
    width: 116px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  .head{
    text-align: center;
     margin-top: 30px;
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: bold;
    /* color: rgb(179, 55, 104);
    text-decoration: underline; 
    text-decoration-color: rgba(8, 77, 108); */
   }
}