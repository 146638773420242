.con{
    /* background: #F8F9FA; */
  }
  
  .imghi{
    height: 16rem;
    width: 25rem;
    margin-right: 10px;
    border-radius: 10px;
    /* border: 10px solid rgba(102, 173, 70); */
  }
  .image-title {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
    font-size: 22px;/* Adjust the margin to control the space between the image and title */
    margin-right: 45px;
  }
  .head{
    text-align: center;
     margin-top: 30px;
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: bold;
    /* color: rgb(179, 55, 104); */
    /* text-decoration: underline; Add underline */
    /* text-decoration-color: rgba(8, 77, 108); */
   }
   .slick-next {
    right: auto; /* Remove the custom right property */
    
  }

/* Mobile Styles */
@media (max-width: 768px) {
  .imghi {
      height: auto;
      width: 100%; /* Make the image width 100% of the container */
      margin-right: 0;
      border-radius: 24px; /* Remove border-radius */
  }

  .image-title {
      font-size: 18px; /* Reduce font size for smaller screens */
      margin: 10px 0; /* Adjust margin for smaller screens */
  }

  .head {
      font-size: 18px; /* Reduce font size for smaller screens */
      margin: 20px 0; /* Adjust margin for smaller screens */
  }
}