.amenities-cont{
    /* The image used */
background-image: linear-gradient(45deg, rgba(0,0,0,0.5), rgba(0,0,0,0.5)) ,url("../assets/ambg.webp"), url("../assets/ambg.webp");

/* Set a specific height */
min-height: 393px;

/* Create the parallax scrolling effect */
background-attachment: fixed;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
position: relative;
}
.amen-logo-cont{
  width: 90px;
  height: 90px;
  /* background: linear-gradient(104deg, rgba(153,98,40,1) 0%, rgba(242,215,156,1) 49%, rgba(195,150,76,1) 100%); */
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.amen-logo-cont img{
  width: 88px;
  margin-top: 54px;
}
.g-20{
  gap:74px;
}
.text-shadow{
  text-shadow: 1px 1px #fff;
}
.amen-logos p{
  width: 200px;
  margin-top: 32px;
  color: white;
  font-weight: bold;
}
.display{
  display: flex;
  margin-left: 17%;
  margin-bottom: 44px;
}

.align{
  text-align: center;
  margin-top: 4%;
  margin-bottom: 4%;
  font-size: 29px;
}

@media screen and (max-width:910px) {
  .amen-logos{
      display: flex;
      justify-content: space-evenly;
      align-items: flex-start;
      /* flex-direction: column; */
  }
  .display{
    display: flex;
    margin-left: 0px;
    margin-bottom: 44px;
  }
  .align{
    text-align: center;
    margin-top: 12%;
    margin-bottom: 12%;
    font-size: 24px;
  }
  .g-20{
    gap:0px;
  }
  .amen-logo-cont img{
    width: 53px;
    margin-top: 54px;
  }
  .amen-logos .row-1, .amen-logos .row-2{
      flex-direction: column;
      justify-content: center !important;
      width: 100%;
      align-items: center !important;
  }
  .amen-logos .row-2{
      margin: 0 !important;
  }
  .amen-logos p{
      width: auto !important;
      height: 0px;
  }
}