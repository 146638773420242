.homecont{
    height: 85vh;
}


/* Home.css */
/* .hero-container {
    position: relative;
  } */
  
  /* Style for the "Hello" heading on mobile */
  .mobile-hero {
    position: relative;
  }
  
  .mobile-hero img {
    width: 100%;
  }
  
  .heading {
    position: absolute;
    top: 50%;
    right: 10px; /* Adjust the right distance as needed */
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.7);
    padding: 10px 20px;
    font-size: 24px;
    font-weight: bold;
    border-radius: 5px;
    text-align: center;
  }
  
  /* Style for the "Hello" heading on desktop */
  .home-slide {
    position: relative;
  }
  
  .home-img {
    width: 100%;
  }
  
  
  .desktop-heading {
    position: absolute;
    top: 70%;
    right: 16%; /* Adjust the right distance as needed */
    transform: translateY(-50%);
    letter-spacing: 3px;
    padding: 10px 20px;
    font-size: 24px;
    font-weight: bold;
    border-radius: 5px;
    text-align: center;
    color: white;
    font-size: 38px;
  }
  

  .desktop-head{
    position: absolute;
    top: 50%;
    right: 10px; /* Adjust the right distance as needed */
    transform: translateY(-50%);
   /* Semi-transparent blue background */
    padding: 10px 20px;
    font-size: 24px;
    font-weight: bold;
    border-radius: 5px;
    text-align: center;
    color: white;
  }
  .overlay{
    background-color: rgba(18 10 10 / 70%);
  }
  .over{
    background-color: rgba(0, 0, 255);
  }
  .hig{
    height: 85vh;
  }

  /* Home.css */
/* ... other styles ... */

/* Style for the logo container */
.logo-container {
    position: absolute;
    top: 10px; /* Adjust the top distance as needed */
    left: 20px; /* Adjust the left distance as needed */
  }
  
  /* Style for the nature logo */
  .nature-logo {
    position: absolute;
    height: 700px;
   
     /* Adjust the top distance as needed */
    right: 20px; /* Adjust the logo width as needed */
  }
  
  /* ... other styles ... */
  
  
  /* Adjust these styles as needed for the heading's appearance */
  
  /* Home.css */
.hero-container {
 
  position: relative;
}

.carousel .carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.carousel .carousel-item img {
  max-width: 100%;
  height: auto;
}

.carousel .control-dots {
    display: none;
}

/* Mobile Styles */
@media (max-width: 600px) {
  .carousel .carousel-item {
    height: auto;
  
  }
 
  .carousel .control-dots {
      display: none;
  }
  .button {
    display: block; /* Display the button on screens with max-width 768px (mobile view) */
  }
  
}
