.about-cont{
    background: #F8F9FA;
}
.buttonn {
    background-image: url('../assets/ambg.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: transparent;
    border: none;
    width: 128px;
    height: 39px;
    cursor: pointer;
    color: #fff;
    border-radius: 10px;
    font-size: 19px;
    font-weight: bold;
  }
  
  .buttonn:hover {
    background-color: #0056b3; /* Change button background color on hover */
  }
  
.about-info-img-container{
    position: relative;
    height: 600px;
    padding: 50px;
}
.about-main-img{
    width: 48%;
    height: vh;
    border: 10px solid #165291;
}
.about-card{
    width: 600px;
    /* border: 1px solid; */
    padding: 40px;
    position: absolute;
    top: 30%;
    background: white;
    left: 46%;
    letter-spacing: 2px;
    line-height: 30px;
    box-shadow: 2px 2px 2px 2px #e4e2e2c4;
    border: 10px solid #165291;
}
.mySwiper{
    height: 660px;
    padding: 30px;
}
.about-sub-img{
    width: 100%;
    height: 100%;
    box-shadow: 2px 2px 2px 2px #7788999c;
}
.about-card p span{
    color: #937445;
}
.about-achivement img{
    width: 100%;
}

@media screen and (max-width:1100px) {
    .about-info-img-container{
        height: auto;
        padding: 20px !important;
    }
     .about-card{
        position: static;
        width: 71%;
        margin-bottom: 20px;
        line-height: 29px;
        letter-spacing: 0px;
     }
     .about-main-img{
       display: none;
     }
}